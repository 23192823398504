<template>
  <div style="height: 100%">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top flex-space-between">
          <el-page-header @back="$router.go(-1)" :content="title"> </el-page-header>
          <div>
            <el-button type="primary" size="small" @click="cancel('form')" plain>取消</el-button>
            <el-button type="primary" size="small" @click="saveData('form')">保存</el-button>
          </div>
        </div>
        <div class="table-line"></div>
        <div class="table-content">
          <el-form ref="form" :model="form" label-width="100px" :rules="rules">
            <div class="title">基本信息<span style="height: 30px; line-height: 30px; color: red; margin-left: 5px; margin-top: 5px">（保存后不能修改）</span></div>
            <el-row :gutter="20" type="flex" justify="space-between">
              <el-col :span="6">
                <el-form-item label="药品名称:" prop="drugId" :rules="rules.drugId">
                  <el-input v-model="form.mzDrug.drugName" placeholder="" disabled v-if="type == 'edit'"></el-input>
                  <el-select class="w-100" v-model="form.drugId" filterable remote clearable placeholder="药品名称" :remote-method="remoteMethod" :loading="isLoading" @change="handleSelect($event)" v-else>
                    <el-option v-for="item in options" :key="item.id" :label="`${item.drugName}${item.drugType==''?'':(item.drugType=='T')?'(统)':(item.drugType=='X')?'(选)':'(精选)'}${item.standardCode?' - '+item.standardCode:''}`" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="别名:">
                  <el-input v-model="form.mzDrug.drugAlias" placeholder="" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="OTC:">
                  <el-radio-group v-model="form.mzDrug.isOTC" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="space-between">
              <el-col :span="6">
                <el-form-item label="药品分类:">
                  <el-select v-model="form.mzDrug.categoryId" placeholder="请选择" style="width: 100%" disabled>
                    <el-option :label="item.categoryName" :value="item.categoryId" v-for="(item, index) in categoryData" :key="index"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="国家标准名称:">
                  <el-input v-model="form.mzDrug.standardName" placeholder="" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="是否为基药:">
                  <el-radio-group v-model="form.mzDrug.isBasice" disabled>
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="space-between">
              <el-col :span="6">
                <el-form-item label="药品毒性:">
                  <el-select v-model="form.mzDrug.drugToxic" placeholder="请选择" disabled clearable style="width: 100%">
                    <el-option :label="item" :value="item" v-for="item in drugToxicity"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="国家标准编码:">
                  <el-input v-model="form.mzDrug.standardCode" placeholder="" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="推荐用量:">
                  <el-input v-model="form.mzDrug.recommendDosage" placeholder="推荐用量" disabled style="width: 80%"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="space-between">
              <el-col :span="6">
                <el-form-item label="商品名:">
                  <el-input v-model="form.productName" placeholder="商品名" :disabled="type == 'edit'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="包装规格:" :prop="`specification`" :rules="rules.specification">
                  <el-row>
                    <el-col :span="15"><el-input v-model="form.specification" placeholder="规格包装描述" :disabled="type == 'edit'"></el-input></el-col>
                    <el-col :span="1">
                      <div class="text-c">/</div>
                    </el-col>
                    <el-col :span="8">
                      <el-select v-model="form.specificationUnit" placeholder="包装单位" :disabled="type == 'edit' || ypId > 0">
                        <el-option :label="item" :value="item" v-for="item in specificationUnit"></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="自定义分类:">
                  <el-input v-model="form.selfCategory" placeholder="自定义分类" :disabled="type == 'edit'" style="width: 80%"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="space-between">
              <el-col :span="6">
                <el-form-item label="批准文号:">
                  <el-input v-model="form.approvalNumber" placeholder="" :disabled="type == 'edit'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" :offset="2">
                <el-form-item label="药品条形码:" :prop="`barCode`" :rules="rules.barCode">
                  <el-input v-model="form.barCode" placeholder="" @change="barCodeChange" :disabled="type == 'edit'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="height: 30px; line-height: 30px; color: red; margin-left: -15px; margin-top: 5px;">（唯一）</span>
              </el-col>
              <el-col :span="6">
                <el-form-item label="生产厂家:" :prop="`productFactory`" :rules="rules.productFactory">
                  <el-input v-model="form.productFactory" placeholder="生产厂家" :disabled="type == 'edit'" style="width: 80%"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="start">
              <el-col :span="6">
                <el-form-item label="产地:">
                  <el-input v-model="form.productPlace" placeholder="" :disabled="type == 'edit'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" :offset="2"> </el-col>
              <el-col :span="6" :offset="4"> </el-col>
            </el-row>
            <div class="title">库存销售设置</div>
            <el-row :gutter="20" type="flex" justify="start">
              <el-col :span="6">
                <el-form-item label="是否拆零:">
                  <el-radio-group v-model="form.isSeparate" :disabled="type == 'edit'">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio> </el-radio-group
                  ><span style="color: red; margin-left: 5px; font-size: 12px">（保存后不支持修改）</span>
                </el-form-item>
              </el-col>
              <el-col :span="6" :offset="2">
                <el-form-item label="拆零单位:" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                  <el-row>
                    <el-col :span="16">
                      <el-input-number controls-position="right" v-model="form.separateNumber" :precision="0" :step="1" :min="0" class="w-100" :disabled="!form.isSeparate || type == 'edit'"></el-input-number>
                    </el-col>
                    <el-col :span="8">
                      <el-select v-model="form.separateNumberUnit" placeholder="单位" :disabled="!form.isSeparate || type == 'edit' || ypId > 0">
                        <el-option :label="item" :value="item" v-for="item in specificationUnit"></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="6" :offset="2">
                <el-form-item label="供应商:">
                  <el-input v-model="form.supplier" placeholder=""></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="start">
              <el-col :span="6">
                <el-row>
                  <el-col :span="20">
                    <el-form-item label=" 库存阈值:">
                      <el-input-number controls-position="right" v-model="form.inventoryMix" :precision="0" :step="1" :min="0" class="w-100"></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <span style="height: 40px; line-height: 40px; margin-left: 5px" v-if="form.isSeparate">{{ form.separateNumberUnit }}</span>
                    <span style="height: 40px; line-height: 40px; margin-left: 5px" v-else>{{ form.specificationUnit }}</span>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="6" :offset="2">
                <el-row>
                  <el-col :span="20">
                    <el-form-item label=" 售价:" prop="tradingPrice">
                      <el-input-number controls-position="right" v-model="form.tradingPrice" :precision="2" :step="1" :min="0" class="w-100" style="position: relative"></el-input-number>
                      <span style="height: 40px; line-height: 40px; margin-left: 5px; color: red; position: absolute; left: 0; top: 30px; font-size: 12px" v-if="type == 'edit'">将会修改所有批次的价格</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <span style="height: 40px; line-height: 40px; margin-left: 5px" v-if="form.isSeparate">元/{{ form.separateNumberUnit }}</span>
                    <span style="height: 40px; line-height: 40px; margin-left: 5px" v-else>元/{{ form.specificationUnit }}</span>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="6" :offset="2">
                <el-form-item label="货架码">
                  <el-input v-model="form.shelfNumber" placeholder=""></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1"></el-col>
            </el-row>
            <div class="title">使用信息</div>
            <el-row :gutter="20" type="flex" justify="start">
              <el-col :span="6">
                <el-form-item label="用法:">
                  <el-select v-model="form.usage" placeholder="请选择" class="w-100">
                    <el-option :label="item" :value="item" v-for="item in usage"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" :offset="2">
                <el-form-item label="单次剂量:">
                  <el-row>
                    <el-col :span="16"><el-input v-model="form.dosage" placeholder="单次剂量"></el-input></el-col>
                    <el-col :span="8">
                      <el-select v-model="form.dosageUnit" placeholder="剂量单位">
                        <el-option :label="item" :value="item" v-for="item in dosageUnit"></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="使用频次:">
                  <el-select v-model="form.frequency" placeholder="请选择" class="w-100">
                    <el-option :label="item" :value="item" v-for="item in frequency"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="1"></el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="start">
              <el-col :span="8">
                <el-form-item label="默认开药量:">
                  <el-row>
                    <el-col :span="16"><el-input v-model="form.defaultDosage" placeholder=""></el-input></el-col>
                    <el-col :span="4">
                      <span style="height: 40px; line-height: 40px; margin-left: 5px" v-if="form.isSeparate">{{ form.separateNumberUnit }}</span>
                      <span style="height: 40px; line-height: 40px; margin-left: 5px" v-else>{{ form.specificationUnit }}</span>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="炮制方法:">
                  <el-select v-model="form.remark" placeholder="选择炮制方法" class="w-100">
                    <el-option :label="item" :value="item" v-for="item in remark"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient";
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug);
    return {
      OutpatientDomain: outpatient,
      tableData: [],
      addData: {},
      categoryData: [],
      type: this.$route.query.type,
      id: this.$route.query.id,
      drugName: this.$route.query.drugName,
      isLoading: false,
      form: {
        mzDrug: {
          drugName: "",
          drugAlias: "",
          isOTC: false,
          categoryId: "",
          standardName: "",
          isBasice: false,
          drugToxic: "",
          standardCode: "",
          materialName: "",
          recommendDosage: "",
          drugTypeList: [],
          hashKey: "",
        },
        id: 0,
        drugId: null,
        productName: "",
        specification: "",
        specificationUnit: "",
        selfCategory: "",
        approvalNumber: "",
        barCode: "",
        productPlace: "",
        productFactory: "",
        isSeparate: false,
        separateNumber: "",
        separateNumberUnit: "",
        supplier: "",
        inventoryMix: 0,
        tradingPrice: 0,
        shelfNumber: "",
        usage: "",
        dosage: "",
        dosageUnit: "",
        frequency: "",
        defaultDosage: "",
        defaultDosageUnit: "",
        remark: "",
      },
      specificationUnit: ["盒", "瓶", "支", "袋", "丸", "枚", "片", "粒", "板", "g"],
      dosageUnit: ["片", "粒", "支", "袋", "枚", "瓶", "滴", "适量", "ug", "mg", "g", "ul", "ml", "IU"],
      usage: ["口服", "外用", "滴眼", "静脉注射", "静脉滴注", "肌内注射", "皮下注射", "皮内注射", "腔内注射", "椎管注射", "动脉注射", "心内注射", "球内注射", "皮下埋植", "口腔喷雾", "口腔吸入", "口腔黏膜给药", "滴鼻", "鼻腔喷雾", "鼻饲", "鼻腔吸入", "滴耳", "眼科外用", "涂于眼睑内", "含漱", "含服", "舌下含服", "直肠给药", "直肠塞入", "肛门涂抹", "阴道给药", "阴道塞入", "阴道擦洗"],
      frequency: ["1日1次(qd)", "1日2次(bid)", "1日3次(tid)", "隔日1次(qod)", "必要时(prn)", "1日4次(qid)", "1周1次(qwd)", "隔周1次(qow)", "隔天1次(qod)", "每晚1次(qn)", "立即(st)"],
      remark: ["先煎", "后下", "包煎", "另煎", "烊化", "冲服", "捣碎"],
      separateNumberUnit: ["片", "粒", "支", "袋", "丸", "枚", "瓶"],
      drugToxicity: ["普通性药品", "剧毒性药品", "放射性药品", "精神性药品", "麻醉药品", "专项精神", "麻醉药", "无毒", "有毒", "第一类精神药品", "第二类精神药品"],
      title: "",
      rules: {
        drugId: [{ required: true, message: "请选择药品名称", trigger: "change" }],
        categoryId: [{ required: true, message: "请选择药品分类", trigger: "change" }],
        barCode: [{ required: true, message: "请输入药品条形码", trigger: "blur" }],
        specification: [{ required: true, message: "请输入规格包装描述", trigger: "blur" }],
        productFactory: [{ required: true, message: "请输入生产厂家", trigger: "blur" }],
        separateNumberUnit: [{ required: true, message: "请选择拆零单位", trigger: "change" }],
      },
      options: [],
      barCode: "",
      ypId: 0,
    };
  },
  mounted() {
    this.LoadCategory();
    if (this.type == "add") {
      this.title = "新增药品";
    }
    if (this.type == "edit") {
      this.title = "编辑药品";
      this.getDetail();
    }
    if (this.type == "info") {
      this.title = "药品详情";
      this.getDetail();
    }
    if (this.drugName) {
      this.form.drugName = this.drugName;
    }
  },
  methods: {
    getSpecificationUnit(e) {
      // this.form.defaultDosageUnit = e;
    },
    barCodeChange() {
      if (this.barCode != this.form.barCode) {
        this.type = "info";
      } else {
        this.type = "edit";
      }
    },
    remoteMethod(queryString) {
      var _this = this;
      if (queryString !== "") {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          _this.OutpatientDomain.FindInOrg(
            queryString,
            function (res) {
              _this.options = res.data;
            },
            function (error) {
              console.log("药品列表请求失败!请刷新重试:", error);
            }
          );
        }, 200);
      } else {
        this.options = [];
      }
    },
    // async querySearchAsync(queryString, cb) {
    //   var results = await this.getSearchRes(queryString);
    //   this.timeout = setTimeout(() => {
    //     cb(results);
    //   }, 3000 * Math.random());
    // },
    // getSearchRes(queryString) {
    //   var _this = this;
    //   return new Promise((resolve, reject) => {
    //     _this.OutpatientDomain.FindInOrg(
    //       queryString,
    //       function (data) {
    //         data.data.forEach((item) => {
    //           _this.$set(item, "value", `${item.drugName}（${item.specification}/${item.specificationUnit}-${item.productFactory}）`);
    //         });
    //         resolve(data.data);
    //       },
    //       function (err) {
    //         console.log(err);
    //         resolve([]);
    //       }
    //     );
    //   });
    // },

    handleSelect(id) {
      let item=this.options.filter(i=>i.id==id)[0]
      if (item) {
        // this.form.drugId = item.id;
        this.form.mzDrug = item;
        if (item.category.categoryName == "饮片") {
          this.form.specificationUnit = "g";
          this.ypId = item.category.categoryId;
          this.form.specificationUnit = "g";
          this.form.defaultDosageUnit = "g";
          this.form.separateNumberUnit = "g";
        }
      }
    },
    getDetail() {
      var _this = this;
      _this.OutpatientDomain.MZDrugOrgDetail(
        this.id,
        function (data) {
          _this.form.id = data.data.id;
          _this.form.drugId = data.data.drugId;
          _this.form.mzDrug = data.data.mzDrug;
          _this.form.specification = data.data.specification;
          _this.form.specificationUnit = data.data.specificationUnit;
          _this.form.selfCategory = data.data.selfCategory;
          _this.form.approvalNumber = data.data.approvalNumber;
          _this.form.barCode = data.data.barCode;
          _this.form.productPlace = data.data.productPlace;
          _this.form.productFactory = data.data.productFactory;
          _this.form.isSeparate = data.data.isSeparate;
          _this.form.separateNumber = data.data.separateNumber;
          _this.form.separateNumberUnit = data.data.separateNumberUnit;
          _this.form.supplier = data.data.supplier;
          _this.form.inventoryMix = data.data.inventoryMix;
          _this.form.tradingPrice = data.data.tradingPrice;
          _this.form.shelfNumber = data.data.shelfNumber;
          _this.form.usage = data.data.usage;
          _this.form.dosage = data.data.dosage;
          _this.form.dosageUnit = data.data.dosageUnit;
          _this.form.frequency = data.data.frequency;
          _this.form.defaultDosage = data.data.defaultDosage;
          _this.form.defaultDosageUnit = data.data.defaultDosageUnit;
          _this.form.remark = data.data.remark;
          _this.form.productName = data.data.productName;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    saveData(formName) {
      var _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (_this.form.isSeparate && (!_this.form.separateNumber || !_this.form.separateNumberUnit)) {
            _this.$message({
              message: "请输入拆零单位",
              type: "error",
            });
            return;
          }
          _this.form.defaultDosageUnit = _this.form.isSeparate ? _this.form.separateNumberUnit : _this.form.specificationUnit;
          if (_this.form.id) {
            _this.OutpatientDomain.EditDrugOrg(
              _this.form,
              function (data) {
                _this.$message({
                  message: "修改成功",
                  type: "success",
                });
                _this.$router.go(-1);
              },
              function (err) {
                var jsonObj = JSON.parse(err.msg);
                if (jsonObj.code == "400") {
                  _this.$message({
                    type: "error",
                    message: jsonObj.msg,
                  });
                }
              }
            );
          } else {
            _this.OutpatientDomain.AddDrugOrg(
              _this.form,
              function (data) {
                if (data.data.id) {
                  _this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  if (_this.drugName) {
                    sessionStorage.setItem("drugInfo", JSON.stringify(data.data));
                  }
                  _this.$router.go(-1);
                } else {
                  _this.$message({
                    type: "error",
                    message: "药品添加失败",
                  });
                }
              },
              function (err) {
                var jsonObj = JSON.parse(err.msg);
                if (jsonObj.code == "400") {
                  _this.$message({
                    type: "error",
                    message: jsonObj.msg,
                  });
                }
              }
            );
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    LoadCategory() {
      var _this = this;
      _this.OutpatientDomain.GetOutpatientCategories(
        function (data) {
          _this.categoryData = data.data.categories;
        },
        function (err) {
          console.log(err);
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.iq-card-body {
  padding: 0 0 50px;
  height: auto;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {
    padding-left: 20px;

    .title {
      background: #f4f5f7;
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      margin-bottom: 20px;
      margin-right: 20px;
      border-radius: 5px;
    }

    ::v-deep .el-table__row > td {
      /* 去除表格线 */
      border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    ::v-deep .el-table .el-table__cell {
      padding: 0 !important;
    }

    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }
  }
}

.drawer_body {
  padding: 0 20px 20px;
}

.searchbox {
  position: relative;
}

.category_box {
  padding: 15px 0;
  border-bottom: 1px solid #eeeeee;
}

.category_box .el-button {
  margin: 5px;
}

.category_box .el-button.active {
  color: #fff;
  background-color: var(--baseColor);
  border-color: var(--baseColor);
}

.tab /deep/ .el-form-item__content {
  height: 55px;
}

.tab /deep/ .el-form-item__content .el-form-item__error {
  top: 40px;
}

.elSelect /deep/ .el-select-dropdown__item span {
  max-width: 170px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .el-autocomplete-suggestion {
  width: auto !important;
  min-width: 300px !important;
}
</style>
